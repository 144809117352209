import {
  OrderItem,
  MediaTypeEnum,
  MediaTypeProps,
  NFTCatalogDoc,
  NFTTokenDoc,
  D3CatBoostEnum,
  TabThemeProps,
  CurrencyEnum,
  ThemeEnum,
  BankAccountStatusEnum,
  packConfig,
  CircleRadioInterface,
  appWebViewDataType,
  UserProfileProps,
  RefereeActivityProps,
  IplRefererPageEnum,
  PaymentKycStateEnum,
  riskProfileStatusEnum,
  passwordValidationTypes,
} from 'interfaces'
import { ThemeType, trackEvent } from '@rario/shared-components'
import { createMessage, encrypt as pgpEncrypt, readKey } from 'openpgp'
import { serialize } from 'cookie'
import {
  walletTransactionSourceEnum,
  walletTransactionStatusEnum,
  walletTransactionTypeEnum,
} from 'interfaces/wallet'
import { WelcomeStateEnum } from 'interfaces/homePage'
import { WheelEvent } from 'react'
import { REFERER } from './urlUtils'
import { NAME_REGEX } from 'constant/transaction'
import dayjs from 'dayjs'
import {
  BurnCampaignRulesViolatingMessage,
  BurnCardInUseMessage,
  BurnCardOnSaleMessage,
  BurnCardProcessingMessage,
  BurngOnSaleListingMessage,
  BurnSelectionCompleteMessage,
} from 'constant/burnEarn'
import { BuybackEstimateProps } from 'interfaces/buyBack'

const monthFullNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthShortlNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const formatLastLoginDateTime = (date: any) => {
  const givenDate = new Date(date)
  let hours = givenDate.getHours()
  const minutes = givenDate.getMinutes()
  const seconds = givenDate.getSeconds()
  const year = givenDate.getFullYear()
  const monthName = monthShortlNames[givenDate.getMonth()]
  const dayName = days[givenDate.getDay()]
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours %= 12
  hours = hours || 12
  const min = minutes < 10 ? `0${minutes}` : minutes
  const formattedDate = `${dayName}, ${monthName} ${givenDate.getDate()}, ${year} ${hours}:${min}:${seconds} ${ampm}`
  return formattedDate
}

export const isFutureDate = (date: any) => {
  const givenDate = new Date(date)
  const todayDate = new Date()
  return givenDate > todayDate
}

export const twentyFourHourAgo = (date: any) => {
  const createData = new Date(date)
  const now = Date.now()
  const oneDay = 24 * 60 * 60 * 1000
  return now - createData.getTime() > oneDay
}

const formatAMPM = (date: Date) => {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours %= 12
  hours = hours || 12
  const min = minutes < 10 ? `0${minutes}` : minutes

  const strTime = `${hours}:${min} ${ampm}`

  return strTime
}

export const formatTwentyFourHours = (date: Date, showColon = true) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const min = minutes < 10 ? `0${minutes}` : minutes
  const hour = hours < 10 ? `0${hours}` : hours
  const strTime = showColon ? `${hour}:${min}` : `${hour}h ${min}m`
  return strTime
}

export const formatDates = (
  date: any,
  showTime: boolean = true,
  showYear: boolean = true,
  monthType: string | undefined = ''
) => {
  const d = new Date(date)
  const month =
    monthType === 'monthShortName' ? monthShortlNames[d.getMonth()] : monthFullNames[d.getMonth()]
  let day = '' + d.getDate()
  const year = d.getFullYear()
  const time = formatAMPM(d)

  if (day.length < 2) {
    day = '0' + day
  }

  const yearText = showYear ? year : ''
  const timeText = showTime ? ` ${time}` : ''

  return [day, month].join(' ') + ` ${yearText}${timeText}`
}

export const epochToUTC = (epochTime: number, showTime: boolean = false) => {
  const d = new Date(epochTime * 1000)
  const month = `${monthShortlNames[d.getMonth()]}, `
  let day = '' + d.getDate()
  const year = d.getFullYear()
  const time = formatTwentyFourHours(d)

  if (day.length < 2) {
    day = '0' + day
  }
  return [day, month, year].join(' ') + `${showTime ? ` | ${time}` : ''} `
}

const WeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const epochToUTCFormat = (epochTime: number) => {
  const d = new Date(epochTime * 1000)
  const month = `, ${monthShortlNames[d.getMonth()]} `
  let day = ' ' + d.getDate()
  const dayName = WeekDays[d.getDay()]

  if (day.length < 2) {
    day = '0' + day
  }
  return dayName + [month, day].join(' ')
}

export const formatDate_ddmmmyy = (
  date: Date | string | number | any,
  showTime: boolean = true,
  style: boolean = false
) => {
  if (typeof date === 'number') {
    date = date * 1000
  }
  const d = new Date(date)
  const month = `${monthShortlNames[d.getMonth()]}, `
  let day = '' + d.getDate()
  const year = d.getFullYear()
  // const time = formatAMPM(d)
  const time = style ? formatTwentyFourHours(d) : formatAMPM(d)

  if (day.length < 2) {
    day = '0' + day
  }
  return [day, month, year].join(' ') + `${style ? ' | ' : ''}` + `${showTime ? ` ${time}` : ''} `
}

export const formatDateSaleHistory = (date: string) => {
  const d = new Date(date)
  // let month = '' + monthShortlNames[d.getMonth()]
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()
  if (month.length < 2) {
    month = `0${month}`
  }
  if (day.length < 2) {
    day = '0' + day
  }
  month = '-' + `${month}` + '-'
  let hours = d.getHours()
  let minutes: string | number = ''
  minutes = d.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  // return strTime
  const returnDate = [day, month, year].join('')
  return [returnDate, strTime].join(' | ')
}

const getConversionPrice = (price: number, exchangePrice: number) => {
  return exchangePrice && (price = price * exchangePrice)
}

export const convertToInternationalCurrencySystem = (labelValue: number) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? Math.sign(Number(labelValue)) * Number((Math.abs(labelValue) / 1.0e9).toFixed(1)) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? Math.sign(labelValue) * Number((Math.abs(labelValue) / 1.0e6).toFixed(1)) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) > 999
    ? Math.sign(labelValue) * Number((Math.abs(labelValue) / 1000).toFixed(1)) + 'k'
    : Math.sign(labelValue) * Math.abs(labelValue)
}

export const priceFormatter = (
  num: number,
  price_currency: string = CurrencyEnum.DEFAULT,
  exchangePrice?: number
) => {
  if (num <= 0 || !num) {
    return '-'
  }

  price_currency !== CurrencyEnum.DEFAULT &&
    exchangePrice &&
    (num = getConversionPrice(num, exchangePrice))

  if (Intl && 'NumberFormat' in Intl) {
    const fraction = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    const formatter = new Intl.NumberFormat('en-US', {
      // @ts-ignore
      // notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 2,
    })

    if (num % 1 === 0) {
      return fraction.format(num)
    } else {
      return formatter.format(num)
    }
  }
  return num.toFixed(2)
}

export const formatPrice = (
  price: number,
  exchangePrice: number = 1,
  ceil: boolean = false,
  fixedToDecimal = 2
) => {
  const convertedPrice = getConversionPrice(Number(price), exchangePrice).toString()
  if (!ceil) return Number.parseFloat(Number.parseFloat(convertedPrice).toFixed(fixedToDecimal))
  return Math.ceil(Number.parseFloat(Number.parseFloat(convertedPrice).toFixed(fixedToDecimal)))
}

export const isInt = (n: number) => {
  return n % 1 === 0
}

export const isBrowser = () => typeof window !== 'undefined'

/**
 * Maps url value to the provide params object
 * @param {string} url - url containing the pattern as {} to be replaced by the param value
 * @param {Object} params - contains the map of key {string} value {string} to be mapped against the string
 * @returns {string} - replaced with the value matched against the provide url
 */
export const replaceParamInString = (str: string, params: { [key: string]: any }): string => {
  const regexReqParam = /[^{]+(?=})/g
  const matches = str.match(regexReqParam) || []
  let changedUrl = str
  matches.forEach((match) => {
    const param = params[match] !== undefined ? params[match] : ''
    changedUrl = changedUrl.replace(`{${match}}`, param)
  })
  return changedUrl
}

export const copyToClipboard = (copyText: string) => {
  const el = document.createElement('textarea')
  // Set value (string to be copied)
  el.value = copyText
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('style', 'position: absolute; left: -9999px;')
  el.setAttribute('readonly', '')
  document.body.appendChild(el)
  // Select text inside element
  el.select()
  // Copy text to clipboard
  document.execCommand('copy')
  // Remove temporary element
  document.body.removeChild(el)
}

export const abbrevateString = (from: number, last: number, str?: string) => {
  if (!str) {
    return ''
  }
  if (str.length > from + last + 4)
    return `${str.substring(0, from)}...${str.slice(str.length - last)}`
  return str
}

export const capitilizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// This function will return moment text
// for the scarcity based on the catalog_type
export const cardTierLabel = (scarcity: string, catalogType: string | undefined) => {
  const capitalizedScarcity = capitilizeFirstLetter(scarcity)
  return `${capitalizedScarcity} Tier${catalogType === 'moment' ? ' Moment' : ' Player'} Card`
}

export const getCDNUrl = (path: string, platformCR: boolean = false) => {
  const platform = platformCR ? process.env.PLATFORM_ORIGIN : ThemeEnum.RARIO
  return `${process.env.CDN_ENDPOINT || ''}/${platform}${path}`
}

export const getUserNameInitals = (username: string, firstName?: string, lastName?: string) => {
  if (!(username || firstName || lastName)) return undefined
  const userFirstName = firstName || username
  const firstNameInitial = userFirstName.charAt(0)
  const lastNameInitial = lastName ? lastName.charAt(0) : null
  return lastNameInitial ? `${firstNameInitial}${lastNameInitial}` : `${firstNameInitial}`
}

export const getUserFirstName = (
  email: string = '',
  firstName?: string,
  isFirstLetterCapitalised: boolean = true
) => {
  const userFirstName = firstName || email.split('@')[0]
  return isFirstLetterCapitalised ? capitilizeFirstLetter(userFirstName) : userFirstName
}

export const numberFormatter = (num: string) => {
  return Number(num).toLocaleString('en-US')
}

export const nftSummary = (sale: number | undefined, minted: string, is_pick?: boolean) => {
  if (is_pick) return ``
  return sale ? `*${sale}* on sale/*${numberFormatter(minted)}* total` : ''
}

export const randomColor = (username: string = '') => {
  let hash = 0
  for (let i = 0; i < username.length; i++) {
    hash = username.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = ''
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  let setBlack = false
  const rgb = parseInt(colour, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue
  const luma = 0.299 * r + 0.587 * g + 0.114 * b
  if (luma > 150) {
    setBlack = true
  }
  return [`#${colour}`, setBlack]
}

export const mapCatalogDoc = (packItem: OrderItem): NFTCatalogDoc => {
  const objectID = packItem?.catalogId.toString()
  const catalog_type = packItem?.catalogType
  const name = packItem?.name
  const total_serial_number = packItem?.totalSerialNumber.toString()
  const price_currency = packItem?.currency
  const image = packItem?.imageUrl
  const scarcity = packItem?.scarcity
  const metadata = packItem?.metadata
  const league = metadata?.league?.shortName || ''
  const role = metadata?.player?.role
  const team = metadata?.teamInfo?.shortName
  const opponent_team = metadata?.opponentTeamInfo?.shortName
  const season = metadata?.tournament?.season
  const year = metadata?.tournament?.year.toString()
  const player = metadata?.player?.name
  const match_date = metadata?.match?.eventDate
  const media: MediaTypeProps[] = [
    { name: packItem?.name, src: packItem?.imageUrl, mediaType: 'image' as MediaTypeEnum.IMAGE },
    { name: packItem?.name, src: packItem?.videoUrl, mediaType: 'video' as MediaTypeEnum.VIDEO },
  ]
  const description_text = packItem?.description
  const tournament = metadata?.tournament?.name
  const attributes = {
    league,
    team,
    role,
    season,
    year,
    scarcity,
    tournament,
    player,
    opponent_team,
  }
  const NFTCatalogDoc = {
    objectID,
    catalog_type,
    name,
    total_serial_number,
    attributes,
    price_currency,
    image,
    media,
    description_text,
    match_date,
  }
  return NFTCatalogDoc
}

export const mapTokenDoc = (packItem: OrderItem, purchasedAt: string): NFTTokenDoc => {
  const serial_number = Number(packItem?.serialNumber)
  const catalog_id = packItem.catalogId?.toString()
  const blockchain_token_id = packItem?.tokenId?.toString()
  const status = packItem?.state
  const sale_price = packItem?.price
  const NFTCatalogDoc = mapCatalogDoc(packItem)
  const objectID = NFTCatalogDoc?.objectID
  const catalog_type = NFTCatalogDoc?.catalog_type
  const name = NFTCatalogDoc?.name
  const total_serial_number = NFTCatalogDoc?.total_serial_number
  const price_currency = NFTCatalogDoc?.price_currency
  const image = NFTCatalogDoc?.image
  const purchased_at = purchasedAt
  const mintTransferedAt = packItem.mintTransferedAt
  const playerId = packItem.playerId
  const tokenId = packItem?.tokenId
  const media: MediaTypeProps[] = [
    { name: packItem?.name, src: packItem?.imageUrl, mediaType: 'image' as MediaTypeEnum.IMAGE },
    { name: packItem?.name, src: packItem?.videoUrl, mediaType: 'video' as MediaTypeEnum.VIDEO },
  ]
  const description_text = NFTCatalogDoc?.description_text
  const attributes = NFTCatalogDoc?.attributes
  const NFTTokenDoc = {
    serial_number,
    catalog_id,
    blockchain_token_id,
    status,
    sale_price,
    objectID,
    catalog_type,
    name,
    total_serial_number,
    price_currency,
    image,
    media,
    description_text,
    attributes,
    purchased_at,
    tokenId,
    mint_transfered_at: mintTransferedAt,
    player_id: playerId.toString(),
  }
  return NFTTokenDoc
}

export const encrypt = async (dataToEncrypt: object, publicKey: string) => {
  if (!publicKey) {
    throw new Error('Unable to encrypt data')
  }

  const decodedPublicKey = await readKey({
    armoredKey: window.atob(publicKey),
  })
  const message = await createMessage({ text: JSON.stringify(dataToEncrypt) })
  return pgpEncrypt({
    message,
    encryptionKeys: decodedPublicKey,
  }).then((ciphertext) => {
    return {
      encryptedMessage: window.btoa(ciphertext as string),
    }
  })
}

export const transactionSourceLabelMapping = new Map<string, string>([
  [walletTransactionSourceEnum.ADD_BALANCE, 'add balance'],
  [walletTransactionSourceEnum.REFUND, 'refund'],
  [walletTransactionSourceEnum.WINNING, 'winnings'],
  [walletTransactionSourceEnum.BUYBACK, 'buyback'],
  [walletTransactionSourceEnum.PACK_PURCHASE, 'pack purchase'],
  [walletTransactionSourceEnum.CARD_PURCHASE, 'card purchase'],
  [walletTransactionSourceEnum.CARD_SALE, 'card sale'],
  [walletTransactionSourceEnum.WITHDRAWAL, 'withdrawal'],
  [walletTransactionSourceEnum.WITHDRAWAL_RETURN, 'withdrawal return'],
  [walletTransactionSourceEnum.MANUAL_WITHDRAWAL_RETURN, 'withdrawal return'],
  [walletTransactionSourceEnum.PAYOUT, 'withdrawal'],
  [walletTransactionSourceEnum.WITHDRAWAL_FEE, 'withdrawal fee'],
  [walletTransactionSourceEnum.WITHDRAWAL_FEE_RETURN, 'withdrawal fee return'],
  [walletTransactionSourceEnum.MERCHANT_COMMISION, 'marketplace commission'],
  [walletTransactionStatusEnum.SUCCESS, 'success'],
  [walletTransactionStatusEnum.FAILED, 'failed'],
  [walletTransactionStatusEnum.PENDING, 'pending'],
  [walletTransactionSourceEnum.MARKETING_CREDITS, 'rario credits'],
  [walletTransactionSourceEnum.CAMPAIGN, 'campaign'],
  [walletTransactionSourceEnum.EXPIRED, 'bonus expired'],
  [walletTransactionSourceEnum.BONUS_REDEEMED, 'bonus applied'],
  [walletTransactionSourceEnum.REFERRER_CREDITS, 'referral'],
  [walletTransactionSourceEnum.PICK_PURCHASE, 'pick purchase'],
  [walletTransactionSourceEnum.D11_BONUS, 'rario credits'],
  [walletTransactionSourceEnum.TRIAL_CARD_BONUS, 'trial bonus'],
  [walletTransactionSourceEnum.MARKETING, 'reward'],
  [walletTransactionSourceEnum.INTERNAL_TESTING, 'reward'],
  [walletTransactionSourceEnum.SIGNUP_BONUS, 'signup bonus'],
  [walletTransactionSourceEnum.TDS_DEDUCTION, 'Towards TDS'],
  [walletTransactionSourceEnum.CS_DEBIT, 'Deduct (Customer Support)'],
  [walletTransactionSourceEnum.CS_CREDIT, 'Add (Customer Support)'],
  [walletTransactionSourceEnum.BURN_EARN, 'winnings'],
  // [walletTransactionSourceEnum.REFEREE_CREDITS, 'signup via referral'],
])

export const activityLabelMapping = new Map<string, string>([
  [walletTransactionTypeEnum.CREDIT, 'CREDITED'],
  [walletTransactionStatusEnum.EXPIRED, 'EXPIRED'],
])

export const getBlockchainTokenUrl = (blockchainTokenId?: string) => {
  if (typeof window === 'undefined') {
    return `${process.env.BLOCK_EXPLORER_URL}token/${process.env.TOKEN_CONTRACT_HASH}/instance/${blockchainTokenId}/token-transfers`
  }
}

export const d3CatBoostMapping = new Map<string, string>([
  [D3CatBoostEnum.BLUE, '5'],
  [D3CatBoostEnum.BRONZE, '10'],
  [D3CatBoostEnum.SILVER, '20'],
  [D3CatBoostEnum.GOLD, '30'],
  [D3CatBoostEnum.BLACK, '50'],
])

export const NSBBoostMapping = new Map<string, string>([
  [D3CatBoostEnum.BRONZE, '10%'],
  [D3CatBoostEnum.SILVER, '12%'],
  [D3CatBoostEnum.GOLD, '15%'],
  [D3CatBoostEnum.BLACK, '20%'],
])

export const UTCToEpoch = (time: string, showInMilliSeconds?: boolean): number => {
  return showInMilliSeconds ? new Date(time).getTime() : Math.floor(new Date(time).getTime() / 1000)
}
export const TabBackgroundVariant = (key: string, theme: ThemeType) => {
  const variant: { [key: string]: TabThemeProps } = {
    CR: {
      selected_background: `radial-gradient(47.98% 54.83% at 89.69% 79.67%, rgba(196, 233, 58, 0.3) 0%, rgba(196, 233, 58, 0) 100%)`,
      selected_borderColor: theme.colors.secondary,
    },
    RARIO: {
      selected_background: `radial-gradient(47.8% 54.28% at 89.69% 80.22%, rgba(249, 150, 107, 0.3) 0%, rgba(251, 151, 108, 0) 100%)`,
      selected_borderColor: `radial-gradient(110.78% 280.38% at 7.73% 5.17%, #FF996D 75%, rgba(255, 153, 109, 0.9) 23.47%,  rgba(255, 153, 109, 0.4) 107.21%, #FF996D 115%)`,
    },
    WEBVIEW_APP: {
      selected_background: `radial-gradient(47.8% 54.28% at 89.69% 80.22%, rgba(249, 150, 107, 0.3) 0%, rgba(251, 151, 108, 0) 100%)`,
      selected_borderColor: `radial-gradient(110.78% 280.38% at 7.73% 5.17%, #FF996D 75%, rgba(255, 153, 109, 0.9) 23.47%,  rgba(255, 153, 109, 0.4) 107.21%, #FF996D 115%)`,
    },
  }
  return variant[key]
}

export const getCurrency = (currency?: string) => {
  return (typeof window === 'undefined' && process.env.PLATFORM_ORIGIN === ThemeEnum.CR) || currency
    ? currencyUIMapping.get(currency ?? 'Default')
    : '$'
}

export const currencyMapping = new Map<string | undefined, string>([
  [ThemeEnum.RARIO, CurrencyEnum.IN],
  [ThemeEnum.WEBVIEW_APP, CurrencyEnum.IN],
  [ThemeEnum.CR, CurrencyEnum.AU],
])

const currencyUIMapping = new Map<string, string>([
  ['Default', 'USD\u00A0'],
  [CurrencyEnum.IN, '₹'],
  [CurrencyEnum.AU, 'AUD\u00A0'],
  [CurrencyEnum.USD, 'USD\u00A0'],
])

export const isUSPhoneNumber = (phoneIsoCode: string): boolean => {
  return phoneIsoCode === '+1'
}

export const getActivityTimeFormat = (timeStamp: any) => {
  if (!(timeStamp instanceof Date)) {
    timeStamp = new Date(timeStamp)
  }

  if (isNaN(timeStamp.getDate())) {
    return 'Invalid date'
  }

  const now = new Date()
  const secondsPast = (now.getTime() - timeStamp.getTime()) / 1000

  if (secondsPast < 0) {
    // Future date
    return 'Invalid date'
  }
  if (secondsPast < 60) {
    // Less than a minute
    const second = Math.floor(secondsPast)
    return second + (second > 1 ? ' secs ago' : ' sec ago')
  }
  if (secondsPast < 3600) {
    // Less than an hour
    const minute = Math.floor(secondsPast / 60)
    return minute + (minute > 1 ? ' mins ago' : ' min ago')
  }
  if (secondsPast <= 86400) {
    // Less than a day
    const hour = Math.floor(secondsPast / 3600)
    return hour + (hour > 1 ? ' hours ago' : ' hour ago')
  }
  // More Than a Day Logics
  const dateDiff = (secondsPast / 86400).toFixed()
  return dateDiff + (Number(dateDiff) > 1 ? ' Days Ago' : ' Day Ago')
}

export function getEpoch(dateTime: Date) {
  const date = new Date(dateTime.toDateString())
  return Math.floor(date.getTime() / 1000)
}

export const bankAccountTextColor = (status: string, theme: ThemeType) => {
  const BANK_ACCOUNT_STATUS: {
    [status: string]: {
      color: string
    }
  } = {
    [BankAccountStatusEnum.COMPLETE]: {
      color: theme.colors.primary,
    },
    [BankAccountStatusEnum.PENDING]: {
      color: theme.colors.atomicTangerine,
    },
    [BankAccountStatusEnum.FAILED]: {
      color: theme.colors.secondary,
    },
  }
  return BANK_ACCOUNT_STATUS[status]
}

type CookieValueType = string | string[]

export function serializeCookies({
  utm_source,
  utm_campaign,
  utm_content,
  utm_medium,
  utm_ref,
  in_app_redirect_url,
  redirect_to,
  x_ip_country,
  x_ip,
  app_platform,
  deviceID,
  appVersion,
  appsFyerUID,
  access_token,
  refresh_token,
  appSession,
  maxAge,
}: {
  utm_source?: CookieValueType
  utm_campaign?: CookieValueType
  utm_content?: CookieValueType
  utm_medium?: CookieValueType
  utm_ref?: CookieValueType
  in_app_redirect_url?: CookieValueType
  redirect_to?: CookieValueType
  x_ip_country?: CookieValueType
  x_ip?: CookieValueType
  app_platform?: CookieValueType
  deviceID?: CookieValueType
  appVersion?: CookieValueType
  appsFyerUID?: CookieValueType
  access_token?: CookieValueType
  refresh_token?: CookieValueType
  appSession?: CookieValueType
  maxAge?: number
}) {
  const utmCookies = []

  if (utm_source) {
    utmCookies.push(
      `${serialize('utm_source', utm_source.toString(), {
        path: '/',
        maxAge: 60 * 60 * 24 * 7, // 7 Days
      })}`
    )
  }

  if (utm_campaign) {
    utmCookies.push(
      `${serialize('utm_campaign', utm_campaign.toString(), {
        path: '/',
        maxAge: 60 * 60 * 24 * 7, // 7 Days
      })}`
    )
  }

  if (utm_content) {
    utmCookies.push(
      `${serialize('utm_content', utm_content.toString(), {
        path: '/',
        maxAge: 60 * 60 * 24 * 7, // 7 Days
      })}`
    )
  }

  if (utm_medium) {
    utmCookies.push(
      `${serialize('utm_medium', utm_medium.toString(), {
        path: '/',
        maxAge: 60 * 60 * 24 * 7, // 7 Days
      })}`
    )
  }

  if (utm_ref) {
    utmCookies.push(
      `${serialize('utm_ref', utm_ref.toString(), {
        path: '/',
        maxAge: 60 * 60 * 24 * 7, // 7 Days
      })}`
    )
  }

  if (app_platform) {
    utmCookies.push(
      `${serialize('app-platform', app_platform.toString(), {
        path: '/',
        maxAge: 60 * 60 * 24, // 1 Day
      })}`
    )
  }
  if (deviceID) {
    utmCookies.push(
      `${serialize('app-device-id', deviceID.toString(), {
        path: '/',
        maxAge: 60 * 60 * 24, // 1 Day
      })}`
    )
  }
  if (appVersion) {
    utmCookies.push(
      `${serialize('app-version', appVersion.toString(), {
        path: '/',
      })}`
    )
  }
  if (appsFyerUID) {
    utmCookies.push(
      `${serialize('appsflyer-uid', appsFyerUID.toString(), {
        path: '/',
        maxAge: 60 * 60 * 24, // 1 Day
      })}`
    )
  }

  if (in_app_redirect_url) {
    utmCookies.push(
      `${serialize('in_app_redirect_url', in_app_redirect_url?.toString(), {
        sameSite: 'strict',
        path: '/',
        maxAge: 60 * 60 * 24, // 1 Day
      })}`
    )
  }

  if (redirect_to) {
    utmCookies.push(
      `${serialize('redirect_to', redirect_to?.toString(), {
        sameSite: 'strict',
        path: '/',
        maxAge: 60 * 60 * 24, // 1 Day
      })}`
    )
  }

  if (x_ip_country) {
    utmCookies.push(
      `${serialize('x_ip_country', x_ip_country.toString(), {
        sameSite: 'strict',
        path: '/',
      })}`
    )
  }
  if (access_token) {
    utmCookies.push(
      `${serialize('access_token', access_token.toString(), {
        httpOnly: true,
        secure: true,
        sameSite: 'strict',
        path: '/',
        maxAge: 84600, // 1 Day
      })}`
    )
  }
  if (refresh_token) {
    utmCookies.push(
      `${serialize('refresh_token', refresh_token.toString(), {
        httpOnly: true,
        secure: true,
        sameSite: 'strict',
        path: '/',
        maxAge: 14 * 86400000, // 14 Days
      })}`
    )
  }

  if (appSession) {
    utmCookies.push(
      `${serialize('appSession', appSession.toString(), {
        httpOnly: true,
        secure: true,
        sameSite: 'lax',
        path: '/',
        maxAge: maxAge || 14 * 86400000, // 14 Days
      })}`
    )
  }

  if (x_ip) {
    utmCookies.push(`${serialize('x_ip', x_ip.toString(), { sameSite: 'strict', path: '/' })}`)
  }

  return utmCookies
}

export const truncateString = (end: number, str: string) => {
  if (end >= str.length) return str
  return `${str.slice(0, end)}...`
}

export const isPanValid = (pan: string): boolean => {
  if (pan.length < 10 || pan === '') return false
  const panRegex = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/
  return panRegex.test(pan)
}

export const isSameDate = (date1: number, date2: number) => {
  return (
    new Date(date1 * 1000).getDate() === new Date(date2 * 1000).getDate() &&
    new Date(date1 * 1000).getMonth() === new Date(date2 * 1000).getMonth() &&
    new Date(date1 * 1000).getFullYear() === new Date(date2 * 1000).getFullYear()
  )
}

export const getHeaders = (accessToken?: string) => {
  const headers: {
    [key: string]: string
  } = {
    'content-type': 'application/json',
  }
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  return headers
}

export const packSubtitle = (pack_config: packConfig, isDummyPack: boolean, item_count: number) => {
  const dummyPackDescription = 'Build Your Dream Team'
  const packConfigKeys = Object.keys(pack_config)
  const packConfigValues = Object.values(pack_config)
  let subTitle = ''
  packConfigKeys &&
    packConfigKeys?.forEach((item, index) => {
      subTitle =
        packConfigValues[index] > 0
          ? subTitle +
            `${packConfigValues[index]} ${item}${index === packConfigKeys.length - 1 ? '' : ', '}`
          : ''
    })
  subTitle = subTitle + ` card${item_count > 1 ? 's' : ''}`
  return isDummyPack ? dummyPackDescription : subTitle
}

export const CircleRadioVariant = (variant: string, theme: ThemeType) => {
  const CircleRadioVariant: { [key: string]: CircleRadioInterface } = {
    isprimary: { bgColor: theme.colors.whites[3], borderColor: theme.colors.white },
    isSecondary: {
      bgColor: theme.button.radio.bgColor,
      borderColor: theme.button.radio.borderColor,
    },
  }

  return CircleRadioVariant[variant]
}

export const maskPanNumber = (panNumber: string) => {
  return panNumber
    .split('')
    .map((val: string, index) => (index > 1 && index < 8 ? '*' : val))
    .join('')
}

export const getAppWebViewDataFromLocalStorage = (): appWebViewDataType | undefined => {
  if (typeof window !== 'undefined') {
    const appWebViewData = localStorage.getItem('appWebViewData')
    if (appWebViewData) {
      try {
        const appWebViewParsedData = JSON.parse(appWebViewData)
        const appsflyerIdIntegrations = {
          integrations: {
            AppsFlyer: {
              appsFlyerId: appWebViewParsedData?.appsFlyerUID,
            },
          },
        }
        return {
          ...appWebViewParsedData,
          ...appsflyerIdIntegrations,
        }
      } catch (err) {}
    }

    return undefined
  }
  return undefined
}

export const isAndroid = () => navigator.userAgent.toLowerCase().indexOf('android') > -1
export const isIos = () => navigator.userAgent.toLowerCase().indexOf('iphone') > -1

export const getWelcomeState = (userData?: UserProfileProps) => {
  if (new Date().getTime() - (userData?.createdAt || 0) * 1000 < 3600000)
    return WelcomeStateEnum.NEW_USER
  if (userData?.metadata?.hasPurchased === false) return WelcomeStateEnum.NO_PURCHASE

  return WelcomeStateEnum.NO_USER_TYPE
}

export const apkDownloadHandler = (
  eventName: string,
  ctaText: string,
  appsFlyerAppDownloadLink?: string
) => {
  ;(window as any)?.fbq?.('track', 'Download')
  ;(window as any)?.open?.(appsFlyerAppDownloadLink || process.env.RARIO_WEBVIEW_APK_URL, '_parent')
  trackEvent('Section Interacted', 'click', {
    sub_category: eventName,
    type: 'cta',
    category: 'auth0',
    click_type: 'button',
    click_text: ctaText,
    click_action: 'click_cta_app_download',
  })
}

export const appsFlyerDownloadHandler = () => {
  if (typeof window !== 'undefined' && 'AF_SMART_SCRIPT_RESULT' in window) {
    const resultUrl = (window as any)?.AF_SMART_SCRIPT_RESULT?.clickURL
    return resultUrl
  }
}

export const getUtmValue = (value: string | string[] | undefined) => {
  if (value) {
    if (Array.isArray(value)) {
      return value[value.length - 1]
    }
    return value
  }
  return undefined
}

export const disableWheel = (e: WheelEvent<HTMLInputElement>) => {
  const target = e.target as HTMLInputElement
  target?.blur()
}

export const getFinalSellingPrice = (markedPrice: number, bonusBalance: number) => {
  if (bonusBalance >= markedPrice / 2) return markedPrice / 2
  return markedPrice - bonusBalance
}

export const getFormattedData = (data: RefereeActivityProps[]) => {
  const referralData = []
  for (let i = 0; i < data?.length; i++) {
    const email = data[i]?.userProfile?.email
    const timeStamp = data[i]?.createdAt
    const dataObj = {
      metadata: {
        refereeEmail: email,
      },
      timestamp: timeStamp,
    }
    referralData.push(dataObj)
  }
  return referralData
}

export const getIplPageLink = (utmSource?: string) => {
  const IplTeamPageInfo: { [key: string]: { link: string; name: string } } = {
    GT: { link: '/innercircle/indiat20/gt', name: 'gt' },
    RCB: { link: '/innercircle/indiat20/rcb', name: 'rcb' },
    KKR: { link: '/innercircle/indiat20/kkr', name: 'kkr' },
    PBKS: { link: '/innercircle/indiat20/pbks', name: 'pbks' },
  }
  if (utmSource) {
    const item = utmSource.split('_')[0]
    return IplTeamPageInfo[item]
  }
  return undefined
}

export const getCurrentYear = () => {
  const currentYear = new Date().getFullYear()
  return currentYear
}

export const getIplRedirectionUrl = ({
  basePath,
  refererPage,
  referer,
}: {
  basePath?: string
  refererPage?: string
  referer?: string
}) => {
  let query: string = ''
  if (referer && referer === REFERER.HOME) {
    query += '?referer=home'
    if (refererPage && refererPage === IplRefererPageEnum.INNERCIRCLE) {
      query += `&referer_page=${IplRefererPageEnum.INNERCIRCLE}`
    }
  } else if (refererPage && refererPage === IplRefererPageEnum.INNERCIRCLE) {
    query += `?referer_page=${IplRefererPageEnum.INNERCIRCLE}`
  }
  return basePath + query
}

export const isUsernameValid = (firstName: string | undefined, lastName?: string | undefined) => {
  // Lastname can't exist without firstname
  if (firstName) {
    if (lastName) {
      return NAME_REGEX.test(firstName) && NAME_REGEX.test(lastName)
    } else {
      return NAME_REGEX.test(firstName)
    }
  }
  return false
}

export const downloadBlob = (blob: Blob, filename: string = 'RarioDownload') => {
  const a = document.createElement('a')
  const url = URL.createObjectURL(blob)
  document.body.appendChild(a)
  a.style.display = 'none'
  a.href = url
  a.setAttribute('download', filename)
  a.click()
  window && window.URL.revokeObjectURL(url)
}

export const paymentMethodMapping = new Map<String, String>([
  ['checkout', 'DC/CC'],
  ['cashfree', 'DC/CC/UPI'],
  ['circle', 'Circle'],
  ['wallet', 'wallet'],
  ['inai', 'UPI'],
  ['inai-checkout', 'DC/CC'],
  ['inai-cashfree', 'DC/CC/UPI'],
  ['inai-decentro', 'UPI'],
  ['other', 'other'],
])

export const getFormattedEventName = (eventName: string, addUnderScore: boolean = true) => {
  if (addUnderScore) return eventName.replace(/ /g, '_').toLocaleLowerCase()
  return eventName.split('/')[1].replace(/[-]/g, '_')
}

export const getDiscountedPrice = (salePrice: number, marketingWalletBalance: number) => {
  return salePrice - Math.min(salePrice / 2, marketingWalletBalance)
}

export const getRedirectPathIfRequired = (userData?: UserProfileProps) => {
  if (userData && userData.isAccountDeactivated) {
    return '/deactivated'
  }
  if (userData && !userData?.isEmailVerified) {
    return '/confirmation-email'
  } else if (userData && userData?.riskProfileStatus === riskProfileStatusEnum.BLACKLIST) {
    return '/blocked'
  } else if (userData && userData?.kycStatus !== PaymentKycStateEnum.SUCCESS) {
    return '/kyc'
  } else {
    return undefined
  }
}

export const checkMinutesDiff = (date: Date, minutes: number) => {
  const startTime = dayjs(date)
  const endTime = dayjs(new Date())
  const differenceInMinutes = endTime.diff(startTime, 'minute')
  return differenceInMinutes <= minutes
}

export const getBurnCardDisabledMessage = ({
  isCardInUse,
  isOnSale,
  isScarcityLimitReached,
  isCardBurned,
  isOnListing = false,
  isSelectionComplete = false,
}: {
  isCardInUse: boolean
  isCardBurned: boolean
  isScarcityLimitReached?: boolean
  isOnSale?: boolean
  isOnListing?: boolean
  isSelectionComplete?: boolean
}) => {
  switch (true) {
    case isSelectionComplete:
      return BurnSelectionCompleteMessage

    case isScarcityLimitReached:
      return BurnCampaignRulesViolatingMessage

    case isCardInUse:
      return BurnCardInUseMessage

    case isOnSale:
      if (isOnListing) {
        return BurngOnSaleListingMessage
      } else {
        return BurnCardOnSaleMessage
      }

    case isCardBurned:
      return BurnCardProcessingMessage

    default:
      return BurnSelectionCompleteMessage
  }
}

// initial state of the password validator
export const passwordInitialState: passwordValidationTypes = {
  identical: false,
  special_char: false,
  lower_case: false,
  upper_case: false,
  numbers: false,
  pwd_length: false,
  strength: '',
}

// Passwork strength list info update
export const passwordValidationReducer = (
  state: passwordValidationTypes,
  action: { payload: boolean; type: string }
) => {
  if (action.type === 'identical') {
    return { ...state, identical: action.payload }
  }
  if (action.type === 'special_char') {
    return { ...state, special_char: action.payload }
  }
  if (action.type === 'lower_case') {
    return { ...state, lower_case: action.payload }
  }
  if (action.type === 'upper_case') {
    return { ...state, upper_case: action.payload }
  }
  if (action.type === 'numbers') {
    return { ...state, numbers: action.payload }
  }
  if (action.type === 'pwd_length') {
    return { ...state, pwd_length: action.payload }
  }
  if (action.type === 'special_characters') {
    return { ...state, special_characters: action.payload }
  }
  if (action.type === 'strength') {
    return { ...state, strength: action.payload }
  }
  if (action.type === 'reset') {
    return passwordInitialState
  }
}

export const ToObject = (prop: Object) => {
  if (typeof prop !== 'object' && prop !== undefined) return JSON.parse(prop)
  return prop
}

export function toFixedTrunc(x: number, n: number = 2) {
  if (!x) return 0
  const v = (typeof x === 'string' ? x : x.toString()).split('.')
  if (n <= 0) return v[0]
  let f = v[1] || ''
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`
  while (f.length < n) f += '0'
  return `${v[0]}.${f}`
}

export const getTotalCardCount = (data: BuybackEstimateProps) => {
  const { cards, moments } = data
  let totalCount = 0

  for (const cardType in cards) {
    // eslint-disable-next-line no-prototype-builtins
    if (cards.hasOwnProperty(cardType)) {
      totalCount += cards[cardType].count
    }
  }
  for (const cardType in moments) {
    // eslint-disable-next-line no-prototype-builtins
    if (moments.hasOwnProperty(cardType)) {
      totalCount += moments[cardType].count
    }
  }

  return totalCount
}

export const capitalize = (value: string) => {
  if (value.length === 0) return null
  return String(value[0]).toUpperCase() + String(value).slice(1)
}
