export type OrderGroupData = {
  orderGroupId: string
  catalog: WalletCatalogProps
  listing: WalletListingProps
  orderPayment: OrderPaymentProps
  reservationId: string
  saleType: string
  status: string
  userId: string
  createdAt?: string
  dropStartTime?: string
}

export type WalletItemDetailsProps = {
  transactionType: string
  transactionSource: string
  transactionStatus: string
  type: string
  currency?: string
  amount: number
  createdAt?: number
  paymentWalletTransferId?: string
  settledAmount?: number
  provider?: string
  id?: string
  orderGroup?: OrderGroupData
  orderGroupId?: string
  feeAmount?: number
  withdrawDate?: number

  // withdraw
  fees?: { amount: number }
  fee?: number
  imadRefId?: string
  transferReferenceId?: string
  destinationName?: string
  destinationType?: string
  reasonCode?: string
  payoutId?: string
  bankCharge?: number
  return?: {
    amount: {
      amount: string
    }
    updateDate: string
  }
  updatedAt?: number
  returnFeeRefundTransferId?: string
  providerFees?: { amount: number }

  // bonus
  campaignName?: string
  refereeEmail?: string
  referrerEmail?: string
  addedOn?: string
  expiry?: string | number
  metadata?: ActivityDetailProps
  invoiceUrl?: string
  marketingCampaignId?: string
  campaignId?: string

  // burn & earn
  burnEarnId?: string
}

type WalletTokenProps = {
  serialNumber?: number
  tokenId?: string
}

type WalletCatalogProps = {
  catalogId: number
  catalogImageUrl: string
  token: WalletTokenProps
  descriptionText: string
  itemCount: number
  salePrice: number
  cardType?: string
}

type WalletListingProps = {
  listingId: string
  listingOwnerUserId: string
  listingStatus: string
}

export type WalletFilterType = {
  status: (walletTransactionStatusEnum | walletTransactionSourceEnum)[]
  type: (walletTransactionStatusEnum | walletTransactionSourceEnum)[]
}
interface OrderPaymentProps {
  amount: number
  bidAmount: number
  bidCurrency: string
  buyerPayableAmount: number
  currency: string
  discount: number
  paymentMethod?: string
  serviceFee: number
  serviceFeeRate: number
  txnFee: number
  txnFeeRate: number
  platformFee?: number
}

export type RarioWalletItemProps = {
  type: string
  timestamp: number
  amount: number
  settledAmount?: number
  feeAmount?: number
  fullDetails: WalletItemDetailsProps
  status: string
  source: string
  walletType: string
  orderGroup?: OrderGroupData
  metadata?: ActivityDetailProps
}

export type DetailsVariantChildType = {
  description: { [key: string]: string }
  message: { [key: string]: string }
  icons: { [key: string]: JSX.Element }
  color: string
}

export enum walletTransactionStatusEnum {
  // eslint-disable-next-line no-unused-vars
  SUCCESS = 'success',
  // eslint-disable-next-line no-unused-vars
  FAILED = 'failed',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'in_transit',
  // eslint-disable-next-line no-unused-vars
  EXPIRED = 'expired',
}

export enum walletTransactionTypeEnum {
  // eslint-disable-next-line no-unused-vars
  CREDIT = 'credit',
  // eslint-disable-next-line no-unused-vars
  DEBIT = 'debit',
}

export enum walletTransactionWalletTypeEnum {
  // eslint-disable-next-line no-unused-vars
  MARKETING_WALLET = 'marketing_wallet',
  // eslint-disable-next-line no-unused-vars
  RARIO_WALLET = 'rario_wallet',
}

export enum walletTransactionSourceEnum {
  // eslint-disable-next-line no-unused-vars
  ADD_BALANCE = 'topup',
  // eslint-disable-next-line no-unused-vars
  REFUND = 'refund',
  // eslint-disable-next-line no-unused-vars
  PACK_PURCHASE = 'primary_purchase',
  // eslint-disable-next-line no-unused-vars
  CARD_PURCHASE = 'marketplace_purchase_debit',
  // eslint-disable-next-line no-unused-vars
  CARD_SALE = 'marketplace_purchase_credit',
  // eslint-disable-next-line no-unused-vars
  MERCHANT_COMMISION = 'merchant_commission',
  // eslint-disable-next-line no-unused-vars
  WITHDRAWAL = 'withdrawal',
  // eslint-disable-next-line no-unused-vars
  WITHDRAWAL_RETURN = 'withdrawal_return',
  // eslint-disable-next-line no-unused-vars
  MANUAL_WITHDRAWAL_RETURN = 'manual_withdrawal_return',
  // eslint-disable-next-line no-unused-vars
  PAYOUT = 'payout',
  // eslint-disable-next-line no-unused-vars
  WITHDRAWAL_FEE = 'withdrawal_fee',
  // eslint-disable-next-line no-unused-vars
  WITHDRAWAL_FEE_RETURN = 'withdrawal_fee_return',
  // eslint-disable-next-line no-unused-vars
  BANK_CHARGE_RETURN = 'payout_bank_fee_refund',
  // eslint-disable-next-line no-unused-vars
  WINNING = 'winning',
  // eslint-disable-next-line no-unused-vars
  MARKETING_CREDITS = 'marketing_credits',
  // eslint-disable-next-line no-unused-vars
  CAMPAIGN = 'campaign',
  // eslint-disable-next-line no-unused-vars
  EXPIRED = 'bonus_expiry',
  // eslint-disable-next-line no-unused-vars
  BONUS_REDEEMED = 'redemption',
  // eslint-disable-next-line no-unused-vars
  REFERRER_CREDITS = 'referral',
  // eslint-disable-next-line no-unused-vars
  D11_BONUS = 'd11_bonus',
  // eslint-disable-next-line no-unused-vars
  PICK_PURCHASE = 'pick_purchase',
  // REFEREE_CREDITS = 'referee_credits',
  // eslint-disable-next-line no-unused-vars
  TRIAL_CARD_BONUS = 'trial_card_bonus',
  // eslint-disable-next-line no-unused-vars
  MARKETING = 'marketing',
  // eslint-disable-next-line no-unused-vars
  INTERNAL_TESTING = 'internal_testing',
  // eslint-disable-next-line no-unused-vars
  SIGNUP_BONUS = 'signup',
  // eslint-disable-next-line no-unused-vars
  TDS_DEDUCTION = 'tds_deduction',
  // eslint-disable-next-line no-unused-vars
  CS_DEBIT = 'cs_debit',
  // eslint-disable-next-line no-unused-vars
  CS_CREDIT = 'cs_credit',
  // eslint-disable-next-line no-unused-vars
  BURN_EARN = 'burned_card_bonus',
  // eslint-disable-next-line no-unused-vars
  BUYBACK = 'buyback',
}

export enum WalletFilterEnum {
  // eslint-disable-next-line no-unused-vars
  TYPE = 'type',
  // eslint-disable-next-line no-unused-vars
  STATUS = 'status',
}

export enum CampaignTypeEnum {
  // eslint-disable-next-line no-unused-vars
  AFFILIATE = 'affiliate',
  // eslint-disable-next-line no-unused-vars
  WINNING = 'winning',
  // eslint-disable-next-line no-unused-vars
  REFERRAL = 'referral',
  // eslint-disable-next-line no-unused-vars
  SIGNUP = 'signup',
  // eslint-disable-next-line no-unused-vars
  BUYBACK = 'buyback',
}

export enum RewardTypeEnum {
  // eslint-disable-next-line no-unused-vars
  REFEREE = 'referee',
  // eslint-disable-next-line no-unused-vars
  REFERRER = 'referrer',
}
export interface TranscObjInterface {
  bgColor: string
  icon: JSX.Element
  textColor: string
  description: string
}

export type ActivityDetailProps = {
  currency: string
  amount: number
  refereeUsername: string
  referrerUsername?: string
  refereePhoneNumber: string
  referrerPhoneNumber?: string
  rewardAmount: number
  status: string
  refereeEmail: string
  rewardType: RewardTypeEnum
  referrerEmail?: string
  campaignType?: CampaignTypeEnum
  burnRequestNumber?: string
}
