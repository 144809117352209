import { AppConfig } from 'interfaces'
import { createContext } from 'react'

export enum CountryIsoCode {
  // eslint-disable-next-line no-unused-vars
  INDIA = 'IN',
  // eslint-disable-next-line no-unused-vars
  USA = 'US',
  // eslint-disable-next-line no-unused-vars
  AUSTRALIA = 'AU',
  // eslint-disable-next-line no-unused-vars
  UNITED_KINGDOM = 'GB',
}

export const defaultAppConfig = {
  campaignData: [],
  restrictedRegions: [
    // 'Sikkim', 'Andhra Pradesh', 'Assam', 'Nagaland', 'Tamil Nadu', 'Odisha'
  ],
  USDToINRExchangeRate: 80,
  USDToINRFinalExchangeRate: 83,
  USDToGBPExchangeRate: 0.81,
  USDToAUDExchangeRate: 1.5,
  preferredCurrency: undefined,
  leagueSorting: [''],
  leagueSortingCard: [''],
  teamSortingCard: [''],
  isIndianIP: true,
  isPracticeMatchRequired: false,
  checkoutTransactionFeeRate: 4,
  inaiTransactionFeeRate: 0.75,
  cashfreeTransactionFeeRate: 0.75,
  disableMarketPlaceActivities: false,
  promotionBannerID: '',
  cmsPages: {
    homePageID: '',
    mobileHomePageID: '',
    allLeaguesPageID: '',
    adelaideStikersPageID: '',
    bigBashLeaguePageID: '',
    indianT20LeaguePageID: '',
  },
  ogPotentialLineUpCMSRefID: '',
  referralPageID: '',
  burnEarnPageID: '',
  isPickEnabled: false,
  firstFoldCMSIds: {
    ppcFirstFoldId: '',
    cricketFandomFirstFoldId: '',
    dreamteamFirstFoldId: '',
    dreamsquadFirstFoldId: '',
    dreamsportsFirstFoldId: '',
  },
  burnEarnCMSRefID: '',
  marketplaceFeeRate: 5,
  disableAddFundsDate: '',
  transactionVariableFeePercent: 1,
  transactionFixedFee: 2,
  minWithdrawalLimit: 2.1,
  maxWithdrawalLimit: 60000,
}

export const AppConfigContext = createContext<AppConfig>(defaultAppConfig)
