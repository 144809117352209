import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import SEO from 'components/Seo'
import { MaxWidthLayout } from './Layout.styles'
import { useRouter } from 'next/dist/client/router'
import { Loader, theme, trackEvent, useBreakpoints } from '@rario/shared-components'
import usePageControl from 'hooks/usePageControl'
import SEOMapData from 'utils/SeoData.json'
import {
  appWebViewDataType,
  RegistrationMediumEnum,
  riskProfileStatusEnum,
  SeoRouteProps,
} from 'interfaces'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { useCookies } from 'react-cookie'
import ModalManager from 'components/ModalManager/ModalManager'
import ModalProvider from 'components/ModalManager/ModalProvider'
import ToastManager from '../Toast/ToastManager'
import Header from 'components/Header'
import Footer from 'components/Footer/Footer'
import { API_HEADER_COOKIES, APP_PLATFORM, COOKIES, UTM_COOKIES } from 'constant'
import { getIplPageLink, getRedirectPathIfRequired, getUtmValue } from 'utils/utils'
import { IS_VPN_CHECK_ENABLED, PLATFORM_ORIGIN } from 'config'
import VpnErrorPreview from 'components/VpnErrorPreview/VpnErrorPreview'
import { geoVpnCheck } from 'api/User.api'
import Script from 'next/script'
import CookieConsentBox from 'components/CookieConsentBox'
import * as snippet from '@segment/snippet'
import { useCustomAuth } from 'contexts/CustomAuthProvider'

type Props = {
  children?: ReactNode
}

const Layout = ({ children }: Props) => {
  const router = useRouter()

  const {
    isHomeLandingPage,
    headerControl,
    isKycPage,
    isAddFundsPage,
    isIplLandingPage,
    isSachinLandingPage,
    isIplPage,
    isLeaguePage,
    isLoginSignupPage,
    isReferEarn,
    isBuyBackPage,
  } = usePageControl()

  const [cookies, setCookie, removeCookie] = useCookies([
    UTM_COOKIES.UTM_SOURCE,
    UTM_COOKIES.UTM_CAMPAIGN,
    UTM_COOKIES.UTM_CONTENT,
    UTM_COOKIES.UTM_MEDIUM,
    UTM_COOKIES.UTM_REF,
    API_HEADER_COOKIES.APP_PLATFORM,
    'x_ip',
    'tracking-preferences',
    COOKIES.ROUND_DETAIL,
  ])

  const { pathname } = router
  const [vpnStatus, setVpnStatus] = useState<boolean>(false)
  // const [region, setRegion] = useState<string>('')

  const { user, isLoading } = useCustomUser()
  const { isAuthLoading } = useCustomAuth()
  const riskProfileStatus = user?.riskProfileStatus
  // const { restrictedRegions } = useContext(AppConfigContext)

  const { mobile } = useBreakpoints()
  const [mobileView, showMobileView] = useState(false)

  useEffect(() => {
    showMobileView(mobile)
  }, [mobile])

  useEffect(() => {
    if (isReferEarn) {
      router.push('/')
    }
  }, [router])

  const isRoundDetailCookie = useMemo(() => {
    return Object.keys(cookies).includes(COOKIES.ROUND_DETAIL)
  }, [cookies[COOKIES.ROUND_DETAIL]])

  if (isRoundDetailCookie) {
    removeCookie(COOKIES.ROUND_DETAIL)
  }
  const [seoData, setSeoData] = useState(
    (SEOMapData as any)[PLATFORM_ORIGIN].routes.find(
      (element: SeoRouteProps) => element.route === pathname
    )
  )

  useEffect(() => {
    setSeoData(
      (SEOMapData as any)[PLATFORM_ORIGIN].routes.find(
        (element: SeoRouteProps) => element.route === pathname
      )
    )
  }, [pathname])

  // TODO:APP move to usePageControl hook
  // let cannonicalUrl = ''
  // if (pathname === '/leagues/[...params]') {
  //   cannonicalUrl = `${process.env.RARIO_BASE_URL}${asPath}`

  // }

  // page routing handling
  useEffect(() => {
    const iplUtmMedium = user?.metadata?.registrationMedium === RegistrationMediumEnum.IPL_TEAM_PAGE
    const iplUtmSource = user?.metadata?.registrationSource
    if (getRedirectPathIfRequired(user)) {
      router.push(getRedirectPathIfRequired(user) || '/')
    } else if (iplUtmMedium && iplUtmSource && !isIplPage) {
      const redirectURL = getIplPageLink(iplUtmSource)?.link
      redirectURL && router.push(redirectURL)
    }
  }, [user])

  const isWebviewApp = useMemo(() => {
    return (
      Object.keys(cookies).includes(API_HEADER_COOKIES.APP_PLATFORM) &&
      cookies[API_HEADER_COOKIES.APP_PLATFORM] === APP_PLATFORM.WEBVIEW_APP
    )
  }, [cookies[API_HEADER_COOKIES.APP_PLATFORM]])

  const showConsentBox = !(
    router?.query.app_platform === APP_PLATFORM.WEBVIEW_APP ||
    isWebviewApp ||
    isLoginSignupPage
  )

  const showCookieConsentBanner = !cookies['tracking-preferences']

  const renderSnippet = () => {
    const opts = {
      apiKey: process.env.SEGMENT_KEY || '',
      // note: the page option only covers SSR tracking.
      // Page.js is used to track other events using `window.analytics.page()`
      page: false,
      ...(showConsentBox && { load: false }),
    }

    if (process.env.NODE_ENV === 'development') {
      return snippet.max(opts)
    }

    return snippet.min(opts)
  }

  const handleRNAppEvent = (event: MessageEventInit) => {
    if (typeof event?.data === 'string' && event?.data.includes('statusbarHeight')) {
      localStorage.setItem('appWebViewData', event?.data)
      const mobileAppBaseConfigData: appWebViewDataType = JSON.parse(event?.data)
      mobileAppBaseConfigData?.deviceID &&
        setCookie('app-device-id', mobileAppBaseConfigData?.deviceID, {
          sameSite: 'strict',
          path: '/',
          maxAge: 60 * 60 * 24, // 1 Day
        })
      mobileAppBaseConfigData?.appVersion &&
        setCookie('app-version', mobileAppBaseConfigData?.appVersion, {
          sameSite: 'strict',
          path: '/',
        })
      mobileAppBaseConfigData?.appsFlyerUID &&
        setCookie('appsflyer-uid', mobileAppBaseConfigData?.appsFlyerUID, {
          sameSite: 'strict',
          path: '/',
          maxAge: 60 * 60 * 24, // 1 Day
        })
    }
  }

  let cannonicalUrl = ''
  if (
    pathname === '/leagues/[...params]' ||
    pathname === '/dreamsports' ||
    pathname === '/ppc' ||
    pathname === '/community'
  ) {
    cannonicalUrl = `${process.env.RARIO_BASE_URL}${router?.asPath.slice(1)}`
  }

  const loadSeoContent = () => (
    <SEO
      title={seoData?.title || ''} // TODO:APP default values. Add SEO for static pages.
      description={seoData?.description || ''}
      keywords={seoData?.keywords || ''}
      cannonicalUrl={cannonicalUrl}
    />
  )

  const hideHeader =
    isHomeLandingPage ||
    isKycPage ||
    isIplLandingPage ||
    isSachinLandingPage ||
    isLeaguePage ||
    isLoginSignupPage ||
    isBuyBackPage

  const setUtmCookie = (key: string, value?: string) => {
    if (value) {
      setCookie(key, value, {
        sameSite: 'strict',
        path: '/',
        maxAge: 60 * 60 * 24 * 7, // 7 Days
      })
    }
  }

  useEffect(() => {
    // Document is working on Android
    document.addEventListener('message', handleRNAppEvent)
    return () => document.removeEventListener('message', handleRNAppEvent)
  }, [])

  useEffect(() => {
    // window is working on IOS
    window.addEventListener('message', handleRNAppEvent)
    return () => window.removeEventListener('message', handleRNAppEvent)
  }, [])

  useEffect(() => {
    setCookie(API_HEADER_COOKIES.PLATFORM, PLATFORM_ORIGIN || 'RARIO', {
      sameSite: 'strict',
      path: '/',
      maxAge: 60 * 60 * 24, // 1 Day
    })
  }, [])

  // Setting up the UTM Cookies from URL Query string
  useEffect(() => {
    if (router?.query) {
      const { utm_source, utm_campaign, utm_content, utm_medium, utm_ref } = router?.query

      setUtmCookie(UTM_COOKIES.UTM_SOURCE, getUtmValue(utm_source))

      setUtmCookie(UTM_COOKIES.UTM_CAMPAIGN, getUtmValue(utm_campaign))

      setUtmCookie(UTM_COOKIES.UTM_CONTENT, getUtmValue(utm_content))

      setUtmCookie(UTM_COOKIES.UTM_MEDIUM, getUtmValue(utm_medium))

      setUtmCookie(UTM_COOKIES.UTM_REF, getUtmValue(utm_ref))
    }

    const appPlatform = mobileView ? APP_PLATFORM.MOBILE_WEBSITE : APP_PLATFORM.WEBSITE // user agent
    if (cookies[API_HEADER_COOKIES.APP_PLATFORM] !== APP_PLATFORM.WEBVIEW_APP) {
      if (router.query.app_platform && router.query.app_platform === APP_PLATFORM.WEBVIEW_APP) {
        setCookie(API_HEADER_COOKIES.APP_PLATFORM, router.query.app_platform, {
          sameSite: 'strict',
          path: '/',
          maxAge: 60 * 60 * 24, // 1 Day
        })
      } else {
        setCookie(API_HEADER_COOKIES.APP_PLATFORM, appPlatform, {
          sameSite: 'strict',
          path: '/',
          maxAge: 60 * 60 * 24, // 1 Day
        })
      }
    }
  }, [router?.query])

  useEffect(() => {
    if (showCookieConsentBanner) {
      trackEvent('Section Viewed', 'track', {
        sub_category: 'cookie_consent_manager-global_banner',
        category: 'cookie_consent_manager',
        type: 'widget',
      })
    }
  }, [])

  // Segment page views
  useEffect(() => {
    if (user?.userId) {
      const { email, name, userId } = user
      const phoneNumber = user?.phoneNumber
      ;(window as any)?.analytics?.identify(userId, {
        email,
        name,
        phone: phoneNumber ? `${user?.phoneIsoCode}${phoneNumber}` : '',
      })
      ;(window as any)?.analytics?.alias(userId)
    }
  }, [user?.userId])

  useEffect(() => {
    if (
      (!isLoading && riskProfileStatus === riskProfileStatusEnum.BLACKLIST) ||
      (vpnStatus && IS_VPN_CHECK_ENABLED === 'TRUE')
    ) {
      setSeoData((SEOMapData as any)[PLATFORM_ORIGIN].errorPage)
    }
  }, [vpnStatus, riskProfileStatus])

  const userIp = cookies?.x_ip
  useEffect(() => {
    const getVpnData = async () => {
      const { privacy } = await geoVpnCheck(userIp as string)
      // region && setRegion(region)
      if (privacy) {
        const { hosting, proxy, relay, tor, vpn } = privacy
        setVpnStatus(hosting || proxy || relay || tor || vpn)
      }
    }
    userIp && getVpnData()
  }, [userIp])

  if (vpnStatus && IS_VPN_CHECK_ENABLED === 'TRUE') {
    return (
      <>
        {loadSeoContent()}
        <VpnErrorPreview />
      </>
    )
  }

  return (
    <>
      <Script id="segmnetScript" dangerouslySetInnerHTML={{ __html: renderSnippet() }} defer />
      {isLoading || isAuthLoading ? (
        <Loader height={'100vh'} />
      ) : (
        <>
          <div
            style={{
              maxWidth: theme.maxLayoutWidth,
              margin: 'auto',
              position: isAddFundsPage ? 'inherit' : 'relative',
              overflow: 'hidden',
              background: theme.colors.richBlackLight,
              minHeight: headerControl ? `calc(100vh - 70px)` : '100vh',
            }}
          >
            {pathname !== '/packs/[packId]' && loadSeoContent()}
            <>
              <ModalProvider
              // isRestricted={restrictedRegions.includes(region)}
              >
                {hideHeader ? (
                  <>{children}</>
                ) : (
                  <>
                    <Header />
                    <MaxWidthLayout pt={'20px'}>{children}</MaxWidthLayout>
                  </>
                )}
                <ModalManager />
              </ModalProvider>
            </>
            <Footer />
            <ToastManager />
          </div>
          {showConsentBox && process.env.SEGMENT_KEY && <CookieConsentBox />}
        </>
      )}
    </>
  )
}

export default Layout
