import { FlexBox, TextField, theme } from '@rario/shared-components'
import { Card } from 'components/BuyBack/BuyBack.styles'
import { ScarcityTypes } from 'interfaces/buyBack'

const BuyBackCardInfo: React.FC<{
  name?: string
  priceInUSD?: number
  priceInINR?: string
  tier: ScarcityTypes
  isPrefferdCurrencyUSD: boolean
}> = ({ name, priceInUSD, priceInINR, tier, isPrefferdCurrencyUSD }) => {
  return (
    <FlexBox justifyContent="space-between">
      <FlexBox alignItems={'center'}>
        <Card scarcity={tier} />
        <TextField
          fontSize="12px"
          textTransform="capitalize"
          lineHeight="16px"
          color={theme.colors.wolfram}
          ml="10px"
          fontWeight="400"
          pb={0}
        >
          {name}
        </TextField>
      </FlexBox>

      <FlexBox alignItems={'center'}>
        <TextField fontWeight="600" fontSize="12px" lineHeight="16px" pb={0}>
          ${priceInUSD}{' '}
          {!isPrefferdCurrencyUSD && (
            <>
              <TextField
                fontWeight="600"
                fontSize="12px"
                lineHeight="16px"
                color={theme.colors.wolfram}
              >
                {' '}
                (
              </TextField>

              <TextField
                fontWeight="700"
                fontSize="12px"
                lineHeight="16px"
                color={theme.colors.wolfram}
              >
                {priceInINR}
              </TextField>
              <TextField
                fontWeight="600"
                fontSize="12px"
                lineHeight="16px"
                color={theme.colors.wolfram}
              >
                )
              </TextField>
            </>
          )}
        </TextField>
      </FlexBox>
    </FlexBox>
  )
}

export default BuyBackCardInfo
